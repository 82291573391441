import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Keycloak from "keycloak-js";
import config from "../../../../pweltconfig.json";
import { SubscriptionInfo } from "./SubscriptionInfo";
import { UserInfo } from "./UserInfo";

export type Subscription = {
  id: string;
  plan: {
    id: string;
    name: string;
    description: string;
    pricingMonthly: number;
    pricingYearly: number;
    numberOfUsers: number;
    storageLimit: number;
  };
  startDate: string;
  endDate: string;
  status: string;
};

export const Home = (props: { keycloak: Keycloak | null }) => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { sm: "100%", md: "1700px" },
        pt: 0,
        p: 4,
      }}
    >
      <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
        Dashboard
      </Typography>
      <Grid
        container
        spacing={6}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        <Grid size={{ sm: 12, md: 6, lg: 3 }}>
          <UserInfo />
        </Grid>
        <Grid size={{ sm: 12, md: 6, lg: 3 }}>
          <SubscriptionInfo />
        </Grid>
        <Grid size={{ md: 6, lg: 3 }}></Grid>
      </Grid>
    </Box>
  );
};
