import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

export const SubscriptionInfo = () => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 3,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Subscription
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Plan Name and Details */}
      <Typography variant="subtitle1" color="text.secondary">
        Plan:
      </Typography>
      <Typography variant="body1" fontWeight="bold" gutterBottom>
        Large
      </Typography>

      {/* Billing Info */}
      <Typography variant="subtitle1" color="text.secondary">
        Billing Cycle:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Monthly (Next Billing: Jan 15, 2025)
      </Typography>

      {/* Price */}
      <Typography variant="subtitle1" color="text.secondary">
        Price:
      </Typography>
      <Typography variant="body1" gutterBottom>
        $29.99 / month
      </Typography>

      {/* Renewal Status */}
      <Typography variant="subtitle1" color="text.secondary">
        Renewal Status:
      </Typography>
      <Typography variant="body1" gutterBottom color="error">
        Canceled
      </Typography>

      {/* Actions */}
      {/* <Stack direction="row"> */}
      <Button variant="outlined" sx={{ mt: 2 }}>
        Manage
      </Button>
      {/* </Stack> */}
    </Card>
  );
};
