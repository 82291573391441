import { Card, Divider, Typography } from "@mui/material";

export const UserInfo = () => {
  return (
    <Card variant="outlined" sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Account
      </Typography>

      <Divider sx={{ my: 2 }} />

      {/* Company details */}
      <Typography variant="subtitle1" color="text.secondary">
        Company:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Company 1
      </Typography>

      {/* Billing Info */}
      <Typography variant="subtitle1" color="text.secondary">
        Address:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Some Address
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        Billing Address:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Some Billing Address
      </Typography>

      {/* Contact */}
      <Typography variant="subtitle1" color="text.secondary">
        Contact:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Contact Person
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        Email:
      </Typography>
      <Typography variant="body1" gutterBottom>
        companyperson@email.com
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        Phone:
      </Typography>
      <Typography variant="body1" gutterBottom>
        +46701234567
      </Typography>
    </Card>
  );
};
